import React from "react";

import { FormGroup, Card, Row, Col, FormText } from "reactstrap";
import styles from "components/styles.module.scss";
import { convertToBase64 } from "utills/index.js";

const FileUploadCOmponents = (props) => {
  const {
    item,
    index,
    isResponseWrong,
    errors,
    language,
  } = props;
  const {
    data,
    data: { answer },
  } = item
  const handleFileUpload = async (e) => {
    // const fileData = new FormData();
    // fileData.append("file", e.target.files[0]);
    item.setSelectedOption({
      ...answer[0],
      ext:e.target.files[0]?.name ?? '',
      value:await convertToBase64(e.target.files[0]),
    });
  };
  return (
    <Card
      className={
        isResponseWrong
          ? `${styles["highlight-div"]} ${styles["card-ui"]} m-3 p-3`
          : `${styles["card-ui"]} m-3 p-3`
      }
    >
      <Row>
        <Col md={11} className={`${styles["custom-col"]} pl-1`}>
          <span>
            <span>
              {data.behaviour && data.behaviour === "MANDATORY" ? "*" : null}
            </span>
            {index + 1}.
          </span>
          <div
            className={
              data.behaviour && data.behaviour === "MANDATORY"
                ? `${styles["questions-ui"]} pr-0 pl-2`
                : "pl-2"
            }
            dangerouslySetInnerHTML={{
              __html:
                language === "english"
                  ? data.question
                  : language === "spanish" && data.sp_question
                  ? data.sp_question
                  : language === "spanish" && data.sp_question === undefined
                  ? data.question
                  : language === "bilingual" && data.sp_question
                  ? `${data.sp_question} ${data.question}`
                  : data.question,
            }}
          ></div>
        </Col>
      </Row>
      <div className={`${styles["options-margin"]} mt-2 custom-input-label`}>
        <FormGroup>
          <input
            type="file"
            name="file"
            style={{cursor: `pointer`}}
            accept="image/*,.pdf,.csv,.exl,.xlsx"
            onChange={(e) => handleFileUpload(e)}
          />
        </FormGroup>

        <div>
          <FormText style={{ fontSize: "14px" }} color="danger">
            {errors}
          </FormText>
        </div>
      </div>
    </Card>
  );
};

export default FileUploadCOmponents;
