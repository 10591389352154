import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// Uncomment below if you want to use the service worker for PWA
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, change the following:
serviceWorker.unregister(); // Disable PWA
// serviceWorker.register(); // Enable PWA
