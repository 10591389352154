/* import  GraniteError from 'lib/GraniteError'; */
class MandatoryFeedbackEntity {
  constructor(object={}){
  	/*	if(!object.template_data){
  		throw GraniteError
  	}*/
  	this.definition = object.definition || "";
  	this.data = object  || {};
  	this.selectedOption = [];
    this.sequenceOfConfiguration = object.sequence_of_configuration;
    this.score = object.score;
    this.behaviour = object.behaviour;
    this.wrongRes = false;
 }

  setSelectedOption(val, type){
    if(type ==='multi'){
      if(this.selectedOption.find((i)=>i.value === val.value)){
        this.selectedOption.splice(this.selectedOption.indexOf(val), 1);
      }
      else{
        this.selectedOption.push(val);
      }     
    }
    else{
      this.selectedOption = [val]
    }
  } 

  isResponseWrong(){
    this.wrongRes = true;
  }

  resetForm(){
    this.wrongRes = false;
  }

  toJSON() {
    const { definition , data , sequenceOfConfiguration , score , behaviour  } = this;
    return { definition, behaviour : behaviour, question : data.question , answer: this.selectedOption , score : score , sequence_of_configuration : sequenceOfConfiguration  };
  }

}

export default MandatoryFeedbackEntity;


