import React from 'react';

import PropTypes from 'prop-types';

import MatchWhenAuthorized from 'pages/MatchWhenAuthorised';

import MandatoryFeedbackPage from './MandatoryFeedback';
//import FormsPage from '../Forms/Forms'

const MandatoryFeedbackRoutes = (props) => {
  const { url } = props;
  return (
    // <Routes>
      <MatchWhenAuthorized
        exact
        path={url}
        component={MandatoryFeedbackPage}
      />
    // </Routes>
  );
};

MandatoryFeedbackRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default MandatoryFeedbackRoutes;
