import React from "react";

import { FormGroup, Card, Input, Row, Col, FormText, Label } from "reactstrap";
import styles from "components/styles.module.scss";

const MultiSelectComponent = (props) => {
  const { item, index, isResponseWrong, selectedOption, errors, language } =
    props;
  let { data } = item;
  return (
    <Card
      className={
        isResponseWrong
          ? `${styles["highlight-div"]} ${styles["card-ui"]} m-3 p-3`
          : `${styles["card-ui"]} m-3 p-3`
      }
    >
      <Row>
        <Col md={11} className={`${styles["custom-col"]} pl-1`}>
          <span>
            <span>
              {data.behaviour && data.behaviour === "MANDATORY" ? "*" : null}
            </span>
            {index + 1}.
          </span>
          <div
            className={
              data.behaviour && data.behaviour === "MANDATORY"
                ? `${styles["questions-ui"]} pr-0 pl-2`
                : "pl-2"
            }
            dangerouslySetInnerHTML={{
              __html:
                language == "english"
                  ? data.question
                  : language == "spanish" && data.sp_question
                  ? data.sp_question
                  : language == "spanish" && data.sp_question == undefined
                  ? data.question
                  : language == "bilingual" && data.sp_question
                  ? `${data.sp_question} ${data.question}`
                  : data.question,
            }}
          ></div>
        </Col>
      </Row>
      <div className={`${styles["options-margin"]} mt-2 custom-input-label`}>
        {data &&
          data.answer &&
          data.answer.length &&
          data.answer.map((i, id) => (
            <div 
            className="custom-checkbox custom-control m-7"
            >
              <Input
                type="checkbox"
                className="custom-control-input"
                id={`${index}_${id}`}
                name={
                  language == "english"
                    ? i.value
                    : language == "spanish" && i.sp_value
                    ? i.sp_value
                    : language == "spanish" && i.sp_value == undefined
                    ? i.value
                    : language == "bilingual" && i.sp_value
                    ? `${i.sp_value} | ${i.value}`
                    : i.value
                }
                onChange={(val) => {
                  props.handleChange(val);
                  props.item.setSelectedOption(i, "multi");
                }}
              />
              <Label className="custom-control-label" for={`${index}_${id}`}>
                {language == "english"
                  ? i.value
                  : language == "spanish" && i.sp_value
                  ? i.sp_value
                  : language == "spanish" && i.sp_value == undefined
                  ? i.value
                  : language == "bilingual" && i.sp_value
                  ? `${i.sp_value} | ${i.value}`
                  : i.value}
              </Label>
            </div>
          ))}
        <div>
          <FormText style={{ fontSize: "14px" }} color="danger">
            {errors}
          </FormText>
        </div>
      </div>
    </Card>
  );
};

export default MultiSelectComponent;
