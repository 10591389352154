import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import promiseMiddleware from "redux-promise-middleware";
import reducer from "./ducks";

function configureStore(initialState = undefined, history = null) {
  // Redux DevTools integration
  const composeEnhancers =
    process.env.NODE_ENV === "development" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  // Middleware setup
  const middlewares = [promiseMiddleware];
  if (history) {
    middlewares.push(routerMiddleware(history));
  }

  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  // Create the Redux store
  const store = createStore(reducer, initialState, enhancers);

  return store;
}

export default configureStore;
