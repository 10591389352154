import React from 'react';

import { FormGroup, Input    , Card ,  Row , Col , FormText} from 'reactstrap';
import styles from 'components/styles.module.scss';
import TRMSGS from 'constants/Translations/Localization';

const SingelSelectComponent = (props) => {
  const { item , isResponseWrong , index, errors, language } = props;
    const { data , data : { answer }}=item
    const handleKeyUp = e => {
      e.currentTarget.value = e.currentTarget.value.trimLeft()
  };
  
  return (
     <Card className= {isResponseWrong ? `${styles['highlight-div']} ${styles['card-ui']} m-3 p-3` : `${styles['card-ui']} m-3 p-3`}>
      <Row>
        <Col md={11} className={`${styles['custom-col']} pl-1`}>
          <span><span>{data.behaviour && data.behaviour === 'MANDATORY' ? '*' : null}</span>{index + 1}.</span>
          <div className={data.behaviour && data.behaviour === 'MANDATORY' ? `${styles['questions-ui']} pr-0 pl-2` : 'pl-2'} dangerouslySetInnerHTML={{ __html: (language == 'english' ? data.question : language == 'spanish' && data.sp_question ? data.sp_question : language == 'spanish' && data.sp_question == undefined ? data.question : language == 'bilingual' && data.sp_question ? `${data.sp_question} ${data.question}` : data.question ) }}></div>
        </Col>
      </Row>
      <div className="mt-2">
        <div className='form-group'>
          <Input
            type="textarea"
            id={`${index}`}
            name={`${index}`}
            onBlur={(val)=> {item.setSelectedOption({...answer[0] , value: val.target.value})}}
            placeholder={TRMSGS.writeHere}
            onKeyUp={handleKeyUp}
          />
        </div>
        <div><FormText  style={{fontSize: '14px'}} color="danger">{errors}</FormText></div>
      </div>
    </Card>
  );
};

export default SingelSelectComponent;
