import React from 'react';

import { Route } from 'react-router-dom';

class MatchWhenAuthorized extends React.Component {
  constructor(props) {
    super(props);
    this.checkProfile = this.checkProfile.bind(this);
  }

  checkProfile(props) {
    const { component: Component } = this.props;
    return <Component {...props} />;
  }

  render() {
    const { component: Component, ...rest } = this.props;
    return this.checkProfile({...rest})
  }
}


export default MatchWhenAuthorized;
