import React from "react";
import { createBrowserHistory } from "history";
import { Provider as ReduxProvider } from "react-redux";
import { Routes, Route,BrowserRouter } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { f1 } from "helpers/commonController.js";
import configureStore from "helpers/configureStore";
import MandatoryFeedbackRoutes from "pages/MandatorySurvey/Routes";
import FormsRoutes from "pages/Forms/Routes";
import "./styles/index.global.scss";

const initialState = window.__APP_STATE__;
const history = createBrowserHistory();
const store = configureStore(initialState, history);

document.addEventListener(
  "message",
  (event) => {
    if (event.data) {
      const params = event.data && JSON.parse(event.data);
      if (params.session_id) {
        f1({ session_id: params.session_id, response_id: params.response_id });
      }
    }
  },
  false
);


function checkJson(json){
  try{
    return JSON.parse(json)
  }catch(e){
    return {}
  }
}

window.addEventListener(
  "message",
  (event) => {
    if (event.data) {
      const params = event.data && checkJson(event.data)
      if (params.session_id) {
        f1({ session_id: params.session_id, response_id: params.response_id });
      }
    }
  },
  false
);


const App = () => (
  <ReduxProvider store={store}>
    <BrowserRouter history={history}>
      <Routes>
        <Route path="/" element={<MandatoryFeedbackRoutes url={'/'}/>} />
        <Route path="/form" element={<FormsRoutes url={"/form"}/>} />
      </Routes>
    </BrowserRouter>
  </ReduxProvider>
);

export default App;
