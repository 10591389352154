import React from "react";
import { FormGroup, Card, Input, Row, Col, FormText, Label } from "reactstrap";
import styles from "components/styles.module.scss";
import { useFormikContext } from "formik";

const SingleSelectComponent = ({
  item,
  index,
  isResponseWrong,
  errors,
  language,
  handleChange,
  // setFieldValue,
}) => {
  const { values, setFieldValue } = useFormikContext();
  let { data } = item;

  const getQuestionContent = () => {
    if (language === "english") return data.question;
    if (language === "spanish") return data.sp_question || data.question;
    if (language === "bilingual")
      return `${data.sp_question || ""} ${data.question}`;
    return data.question;
  };

  const getOptionLabel = (item) => {
    if (language === "english") return item.value;
    if (language === "spanish") return item.sp_value || item.value;
    if (language === "bilingual")
      return `${item.sp_value || ""} | ${item.value}`;
    return item.value;
  };

  return (
    <Card
      className={`${styles["card-ui"]} m-3 p-3 ${
        isResponseWrong ? styles["highlight-div"] : ""
      }`}
    >
      <Row>
        <Col md={11} className={`${styles["custom-col"]} pl-1`}>
          <span>
            {data.behaviour === "MANDATORY" && "*"} {index + 1}.
          </span>
          <div
            className={`${
              data.behaviour === "MANDATORY"
                ? `${styles["questions-ui"]} pr-0`
                : ""
            } pl-2`}
            dangerouslySetInnerHTML={{ __html: getQuestionContent() }}
          />
        </Col>
      </Row>
      <div className={`${styles["options-margin"]} mt-2 custom-input-label`}>
        {data?.answer?.map((i, id) => (
          <FormGroup className="custom-radio custom-control" style={{marginBottom:'0 !important'}} key={`${index}_${id}`} check>
            <Input
              type="radio"
              id={`${index}_${id}`}
              name={`${index}`}
              onChange={(e) => {
                handleChange(e);
                if (item.setSelectedOption) {
                  item.setSelectedOption(i);
                }
              }}
              className="custom-control-input"
            />
            <Label className="custom-control-label" for={`${index}_${id}`} check>
              {getOptionLabel(i)}
            </Label>
          </FormGroup>
        ))}
        {errors && (
          <FormText style={{ fontSize: "14px" }} color="danger">
            {errors}
          </FormText>
        )}
      </div>
    </Card>
  );
};

export default SingleSelectComponent;
