import { Subject } from 'rxjs';

class EventEmitter {
  constructor() {
    this.observable = new Subject();
  }

  getObservable() {
    return this.observable;
  }

  emit(event) {
    this.observable.next(event);
  }
}

export default EventEmitter;
